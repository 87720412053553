import classnames from "classnames";
import ease from "ease-component";
import _, { get } from "lodash";
import React from "react";
import Scrollspy from "react-scrollspy";
import scroll from "scroll";
import scrollDoc from "scroll-doc";
import moment from "../../utils/moment-timezone-with-data";
// import debounceRender from "react-debounce-render";
import { Chip, Collapse, Fade } from "@mui/material";
import { Favorite } from "@mui/icons-material";
import MobileDetect from "mobile-detect";
import { Portal } from "react-portal";
import { Sticky, StickyContainer } from "react-sticky";
import {
  ADDRESS,
  BRANCH,
  PROMO_CODE,
  SERVING_OPTION,
  SERVING_OPTION_VALUE,
  TABLE_CODE
} from "../../utils/constants";
import { resetTableCode, resetFeaturedProductToByInOrder } from "../../store/order/actions";
import getLocationClosedText from "../../utils/getLocationClosedText";
import { fireSelectItemEvent } from "../../utils/gtm";
import localeDates from "../../utils/localeDates";
import {
    getParams, navigateTo, removeParams, setParams
} from "../../utils/location";
import { getTableCodeValidator } from "../../utils/table-codes";
import AppContainer from "../AppContainer";
import BagItemsIndicator from "../BagItemsIndicator";
import CheckoutView from "../checkout-view";
import GiftIcon from "../icons/Gift.svg";
import DealsIcon from "../icons/Deals.svg";
import MenuItem from "../Item";
import List from "../List";
import { LOGIN_TYPES } from "../LoginView";
import MenuHeader from "../MenuHeader";
import Modal from "../Modal";
import PromoCode from "../PromoCode";
import TableCode from "../TableCode";
import AddPromoCodeButton from "./add-promo-code-button";
import CategoryLabel from "./category-label";
import CheckDealNotice from "./check-deal-notice";
import CheckItemNotice from "./check-item-notice";
import * as styles from "./index.module.scss";
import MenuNotices from "./menu-notices";
import MenuSearch from "./menu-search";
import PointsCouponNotice from "./points-coupon-notice";
import WarningMessage from "./warning-message";
import ServingOption from "../ServingOption";
import OrderBranch from "../OrderBrach";
import OrderAddress from "../OrderAddress";

let offset = null;
let page = null;

const getOffset = () => {
  if (!offset) {
    offset = require("document-offset");
  }
  return offset;
};

const checkIfTableCodeMissed = (
  { selectedServingOption, branchesAvailability, order, currentBranch },
  params
) => {
  const branchAvailability = branchesAvailability[currentBranch.id];

  const shouldEnterTableCodeFirst =
    _.get(selectedServingOption, "hasTableCodes") &&
    !_.isEmpty(
      _.get(branchesAvailability, `[${params.branchId}].tableCodeRanges`)
    ) &&
    !order.tableCode &&
    (!branchAvailability ||
      _.isEmpty(params.tableCode) ||
      (!_.isEmpty(params.tableCode) &&
        !getTableCodeValidator(branchAvailability)(params.tableCode)));

  return {
    modalOpen: Boolean(shouldEnterTableCodeFirst),
    modalType: TABLE_CODE,
  };
};

const checkIfAddressMissed = (props, params) => {
  const { order, selectedServingOption } = props;
  const deliveryAddress = _.get(order, "deliveryAddress");

  return {
    modalOpen: get(selectedServingOption, "needsAddress") && !get(deliveryAddress, "geoPoint"),
    modalType: ADDRESS,
  };
};

const checkIfBranchMissed = (props, params) => {
  const branchId = _.get(params, "branchId");

  const {currentBranch} = props;

  return {
    modalOpen: !branchId && !currentBranch,
    modalType: BRANCH,
  };
};

const checkIfServingOptionMissed = (props, params) => {
  const servingOption = _.get(params, "servingOptionType");
  const { selectedServingOption, availableServingOptions, currentBranch } = props;

  const availableServingOptionsForBranch = currentBranch ? 
    _.filter(availableServingOptions, so => !_.includes(_.get(so, "unavailableInBranches"),  currentBranch.id)) : availableServingOptions;

  
  const isNewServingOptionType = servingOption && servingOption !== selectedServingOption.type;

  if ((_.get(selectedServingOption, "type") !== servingOption || !servingOption) && availableServingOptionsForBranch.length === 1) {
    //select the only option and continue
    props.setOrderServingOption(availableServingOptionsForBranch[0]);
    navigateTo(
      setParams("/order", {
        ...params,
        servingOptionType: availableServingOptionsForBranch[0].type,
      }),
    );
    return {
      modalOpen: false,
      modalType: null,
    };
  }

  return {
    modalOpen: (!servingOption && (!selectedServingOption || _.isEmpty(selectedServingOption))) || isNewServingOptionType,
    modalType: SERVING_OPTION_VALUE,
  };
};

const checkIfPointTradeCouponNotice = ({ user }, params) => {
  return {
    modalOpen: _.get(user, "displayTradeNotice"),
    modalType: POINT_COUPON_TRADE_NOTICE,
  };
};

const checkIfCouponDealNotice = (props, params) => {
  const checkDeal = _.get(params, "checkdeal");

  return {
    modalOpen: Boolean(checkDeal),
    modalType: CHECK_DEAL_NOTICE,
  };
};

const checkIfItemNotice = (props, params) => {
  const checkItem = _.get(params, "checkitem");

  return {
    modalOpen: Boolean(checkItem),
    modalType: CHECK_ITEM_NOTICE,
  };
};


// const getModalState = (props) => {
//   const {
//     user,
//     location,
//     selectedServingOption,
//     order,
//     branchesAvailability,
//     currentBranch,
//     pageContext: { branches },
//   } = props;
// console.log(branchesAvailability, "branchesAvailability");
//   const params = getParams(location);

//   const branchAvailability = branchesAvailability[currentBranch.id];

//   const shouldEnterTableCodeFirst =
//     _.get(selectedServingOption, "hasTableCodes") &&
//     !_.isEmpty(
//       _.get(branchesAvailability, `[${params.branchId}].tableCodeRanges`),
//     ) &&
//     !order.tableCode &&
//     (!branchAvailability ||
//       _.isEmpty(params.tableCode) ||
//       (!_.isEmpty(params.tableCode) &&
//         !getTableCodeValidator(branchAvailability)(params.tableCode)));

//   const modalOpen =
//     user.displayTradeNotice ||
//     Boolean(params.checkdeal || params.checkitem) ||
//     shouldEnterTableCodeFirst;
//   const modalType = shouldEnterTableCodeFirst
//     ? TABLE_CODE
//     : user.displayTradeNotice
//       ? POINT_COUPON_TRADE_NOTICE
//       : params.checkdeal
//         ? CHECK_DEAL_NOTICE
//         : params.checkitem
//           ? CHECK_ITEM_NOTICE
//           : null;
//   console.log({ modalOpen, modalType });
//   return {
//     modalOpen,
//     modalType,
//   };
// };

const scrollToCategorySectionDuration = 500;
const scrollToCategoryLabelDuration = 500;
const scrollToCategoryLabelDelay = 50;
const scrollToItemDuration = 300;

const scrollToCategoryLabelOptions = {
  ease: ease.inOutEase,
  duration: scrollToCategoryLabelDuration,
};
const scrollToItemOptions = {
  ease: ease.outEase,
  duration: scrollToItemDuration,
};

const sanitizeParam = (param) => {
  if (!param) return undefined;

  const sanitizedParam = param
    .replace(/(\/*)$/g, "")
    .replace(/.+(%20*).+/g, "")
    .replace(/(%2f*)$/g, "");

  return sanitizedParam;
};

const isDisplayOnlyDeal = (deal) =>
  deal.discountRuleType === "percentage" &&
  _.isEmpty(_.get(deal.percentageParams, "discountItems.posItemIds"));

class OrderView extends React.Component {
  topMenuIdsToRefs = {};
  sectionRefsIdsToRefs = {};
  menuItemsToRefs = {};
  preventScrollToCategoryLabel = Boolean(
    getParams(this.props.location).fromItemId
  );

  state = {
    menuItemClicked: null,
    warningMessageHeight: 0,
    menuHeaderHeight: 0,
    shouldScrollToItem: Boolean(getParams(this.props.location).fromItemId),
    scrollingToItem: false,
    scrollingToCategory: false,
    unlockedDealIds: [],
    unlockedDealIdToScrollTo: null,
    layoutReady: false,
  };

  componentDidMount() {
    const {
      location,
      openCheckoutView,
      cancelOrderItemEdit,
      resetGiftRedeem,
      loadLoyaltyProfile,
      saveOrderIfNeeded,
      loadBranchAvailabiltyIfNeeded,
      currentBranch,
      closeCheckoutView,
      order,
      app: { isMobile },
      appStyles: { displayCategoriesImages }
    } = this.props;

    this.getModalState(this.props);

    if (!currentBranch) {
      return navigateTo("/");
    }
    const params = getParams(location);

    clearTimeout(this.layoutReadyTimout);
    this.layoutReadyTimout = setTimeout(() => {
      this.setState({ layoutReady: true });
      if (params.checkoutOpen && !this.isCheckoutOpen()) {
        openCheckoutView();
      }
    }, 1000);
    page = scrollDoc();
    resetGiftRedeem();
    resetFeaturedProductToByInOrder();
    this.setTableCodeIfNeeded();

    this.stickyHeight = isMobile
                ? displayCategoriesImages
                  ? STICKY_CATEGORY_MENU_WITH_IMAGES_HEIGHT
                  : STICKY_CATEGORY_MENU_HEIGHT
                : 0;

    loadBranchAvailabiltyIfNeeded();

    if (this.bounceIndicator) {
      this.bounceIndicator.bounce();
    }

    if (order.editingItem) {
      cancelOrderItemEdit();
    }

    if (params.editMode) {
      navigateTo(removeParams("/order", "editMode"));
    }
    saveOrderIfNeeded();
    this.isSafari = /Apple/.test(navigator.vendor);
    const categoryNameToScrollTo = sanitizeParam(params.toCategoryName);
    if (categoryNameToScrollTo) {
      _.defer(() =>
        this.scrollToCategoryName(categoryNameToScrollTo, 0, {
          isFirst: false,
        })()
      );
    } else {
      const categoryIdToScrollTo = sanitizeParam(params.toCategoryId);

      if (categoryIdToScrollTo) {
        console.log("will scroll to categoryId: ", categoryIdToScrollTo);
        _.defer(() =>
          this.scrollToCategory(categoryIdToScrollTo, 0, { isFirst: false })()
        );
      } else {
        const itemIdToScrollTo = sanitizeParam(params.fromItemId);
        if (this.state.shouldScrollToItem || itemIdToScrollTo) {
          console.log("will scroll to item: ", itemIdToScrollTo);
          this.scrollToMenuItem(itemIdToScrollTo);
        } else {
          window.scrollTo(0, 0);
        }
      }
    }

    if (this.isCheckoutOpen() && !params.checkoutOpen) {
      closeCheckoutView();
    }

    if (!params.checkoutOpen) {
      this.makeScroll(params);
    }

    if (this.props.user.loggedIn) {
      loadLoyaltyProfile();
    }
  }


  getModalState(props) {
    const params = getParams(location);

    const modalChecks = [
      checkIfServingOptionMissed,
      checkIfAddressMissed,
      checkIfBranchMissed,
      checkIfTableCodeMissed,
      checkIfPointTradeCouponNotice,
      checkIfCouponDealNotice,
      checkIfItemNotice,
    ];

    let foundModal = false;
    let newModalOpen = false;
    let newModalType = null;

    for (const check of modalChecks) {
      const { modalOpen, modalType } = check(props, params);
      if (modalOpen) {
        foundModal = true;
        newModalOpen = true;
        newModalType = modalType;
        break;
      }
    }

    if (!foundModal && this.state.modalOpen) {
      newModalOpen = false;
      newModalType = null;
    }

    this.setState({
      modalOpen: newModalOpen,
      modalType: newModalType,
    });
  }

  hasOrderURLParamsChanged(prevParams, params) {
    const { branchId, servingOptionType, checkdeal, checkitem } = params;
    return (
      branchId !== prevParams.branchId ||
      servingOptionType !== prevParams.servingOptionType ||
      checkdeal !== prevParams.checkdeal ||
      checkitem !== prevParams.checkitem
    );
  }

  handleOpenCheckout = () => {
    this.props.openCheckoutView();
    // window.scrollTo(0,0);
  }

  isCheckoutOpen = () => {
    const { app } = this.props;

    const isPhoneWidth = app.isSSR || window.innerWidth < 768;
    const checkoutOpen = isPhoneWidth
      ? app.checkoutOpen && !app.mobileAuthViewOpen
      : !app.userRequestedCheckoutClosedOnDesktop;
    return checkoutOpen;
  };

  setTableCodeIfNeeded = () => {
    const {
      location,
      selectedServingOption,
      order,
      branchesAvailability,
      currentBranch,
      pageContext: { branches },
      setTableCode,
      resetTableCode,
    } = this.props;

    const params = getParams(location);

    if (_.get(selectedServingOption, "hasTableCodes")) {
      if (
        _.isEmpty(
          _.get(branchesAvailability, `[${params.branchId}].tableCodeRanges`)
        )
      ) {
        return;
      }
      if (!_.isEmpty(params.tableCode) && order.tableCode != params.tableCode) {
        const branchAvailability = branchesAvailability[currentBranch.id];
        if (branchAvailability) {
          const isValidTableCode = getTableCodeValidator(branchAvailability);
          if (isValidTableCode(params.tableCode)) {
            setTableCode(params.tableCode);
          }
        }
      } else if (_.isEmpty(params.tableCode) && order.tableCode) {
        resetTableCode();
      }
    }
  };

  componentWillUnmount() {
    const {
      pageContext: { kioskMode },
      closeCheckoutView,
    } = this.props;
    if (!kioskMode) {
      closeCheckoutView();
    }
    this.preventScrollToCategoryLabel = true;
    clearTimeout(this.scrollSetterTimeout);
    clearTimeout(this.scrollToCategoryTimeout);
    clearTimeout(this.layoutReadyTimout);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.giftRedeem.shouldNavigateTo &&
      this.props.giftRedeem.shouldNavigateTo !==
        nextProps.giftRedeem.shouldNavigateTo
    ) {
      navigateTo(nextProps.giftRedeem.shouldNavigateTo);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      openCheckoutView,
      giftRedeem,
      location,
      order,
      isSavingOrder,
      user,
      loadLoyaltyProfile,
      selectedServingOption,
      branchesAvailability,
    } = this.props;
    const { modalOpen, modalType } = this.state;
    this.setTableCodeIfNeeded();
    const params = getParams(location);

    const hasTableCodes =
      _.get(selectedServingOption, "hasTableCodes") &&
      !_.isEmpty(
        _.get(branchesAvailability, `[${params.branchId}].tableCodeRanges`)
      );

    if (this.hasOrderURLParamsChanged(getParams(prevProps.location), params)) {
      this.getModalState(this.props);
    }

    if (
      modalOpen &&
      modalType === TABLE_CODE &&
      params.tableCode &&
      hasTableCodes
    ) {
      this.getModalState(this.props);
    }

    const unlockedDealIds = this.getUnlockedDealIds(order.promoCodes);
    const unlockedDealIdToScrollTo = _.get(
      order,
      "promoCodes[0].unlockedDiscountRuleIds[0]",
    );
    const newUnlockedDeals = !_.isEqual(
      this.getUnlockedDealIds(prevProps.order.promoCodes),
      unlockedDealIds,
    );
    const promoCodeChange =
      !_.isEmpty(
        _.difference(
          _.map(prevProps.order.promoCodes, "code"),
          _.map(order.promoCodes, "code"),
        ),
      ) && order.promoCodes.length > prevProps.order.promoCodes.length;

    if (newUnlockedDeals || promoCodeChange) {
      this.setState(
        {
          unlockedDealIds,
          ...(unlockedDealIdToScrollTo && { unlockedDealIdToScrollTo }),
        },
        () =>
          unlockedDealIdToScrollTo &&
          this.scrollToMenuItem(unlockedDealIdToScrollTo),
      );
    }

    if (
      prevProps.isSavingOrder &&
      !isSavingOrder &&
      this.state.modalType === PROMO_CODE
    ) {
      const hasError = _.get(order, "promoCodes[0].error");
      !hasError && this.onCloseModal();
    }

    if (!prevProps.user.loggedIn && user.loggedIn) {
      loadLoyaltyProfile();
    }

    if (this.isCheckoutOpen()) {
      if (
        !getParams(prevProps.location).checkoutOpen &&
        getParams(location).checkoutOpen
      ) {
        openCheckoutView();
      }
      if (
        !prevProps.giftRedeem.isDuringDealRedeem &&
        giftRedeem.isDuringDealRedeem
      ) {
        if (giftRedeem.shouldNavigateTo) {
          navigateTo(
            setParams(giftRedeem.shouldNavigateTo, {
              itemName: giftRedeem.selectedDeal.description,
              fromItemId: giftRedeem.selectedDeal.id,
            })
          );
        }
      }
    }

    if (prevProps.app.checkoutOpen && !this.props.app.checkoutOpen) {
      this.makeScroll(params);
    }

    if (!_.isEqual(this.props.order, prevProps.order)) {
      this.props.saveOrderIfNeeded({
        force:
          !this.props.order
            .shouldSyncOrderItems &&
          prevProps.order.shouldSyncOrderItems,
      });
    }
  }

  makeScroll = (params) => {
    const showOnlySelectedCategory =
      this.props.app.isMobile && sanitizeParam(params.toCategoryId);

    // when returned from menu item page
    const itemIdToScrollTo = sanitizeParam(params.fromItemId);
    if (this.state.shouldScrollToItem || itemIdToScrollTo) {
      console.log("will scroll to item: ", itemIdToScrollTo);
      return this.scrollToMenuItem(itemIdToScrollTo);
    }

    // when redirected from categories page
    const categoryNameToScrollTo = sanitizeParam(params.toCategoryName);
    if (!this.state.scrollingToCategory && categoryNameToScrollTo) {
      return _.defer(() =>
        this.scrollToCategoryName(categoryNameToScrollTo, this.stickyHeight, {
          isFirst: showOnlySelectedCategory,
        })()
      );
    }

    const categoryIdToScrollTo = sanitizeParam(params.toCategoryId);
    if (!this.state.scrollingToCategory && categoryIdToScrollTo) {
      console.log("will scroll to categoryId: ", categoryIdToScrollTo);
      return _.defer(() =>
        this.scrollToCategory(categoryIdToScrollTo, this.stickyHeight, {
          isFirst: showOnlySelectedCategory,
        })()
      );
    }

    // default scroll
    window.scrollTo(0, 0);
  };

  getItemCategory = (itemId) => {
    const { menuData, location } = this.props;

    const category = _.find(menuData, (category) =>
      _.find(category.items, (item) => item.id === itemId),
    );

    return _.get(category, "id");
  };

  scrollToMenuItem = (itemId) => {
    console.log("trying to scroll to item id:", itemId);
    this.preventScrollToCategoryLabel = true;
    const setScrollingCompleted = () => {
      this.preventScrollToCategoryLabel = false;
      this.setState(
        { scrollingToItem: false, shouldScrollToItem: false },
        () => {
          // we don't remove the params in order to prevent auto scrolling to the head of the page.
          const categoryId = this.getItemCategory(itemId) || "deals";
          clearTimeout(this.scrollToCategoryTimeout);
          this.scrollToCategoryTimeout = setTimeout(() => {
            console.log("scroll to category label for id:", categoryId);
            this.scrollToCategoryLabel(categoryId);
          }, scrollToCategoryLabelDelay);
          if (this.state.unlockedDealIdToScrollTo) {
            this.setState({ animateDealElement: true }, () => {
              clearTimeout(this.elementAnimation);
              this.elementAnimation = setTimeout(
                () =>
                  this.setState({
                    animateDealElement: false,
                    unlockedDealIdToScrollTo: null,
                  }),
                3000,
              );
            });
          }
        },
      );
    };

    if (itemId && this.menuItemsToRefs[itemId]) {
      this.setState(
        { shouldScrollToItem: false, scrollingToItem: true },
        () => {
          try {
            console.log("scrolling to item:", itemId);
            const stickyHeight = this.props.app.isMobile
              ? this.props.appStyles.displayCategoriesImages
                ? STICKY_CATEGORY_MENU_WITH_IMAGES_HEIGHT
                : STICKY_CATEGORY_MENU_HEIGHT
              : 0;
            const padding = 16;
            const menuItemOffset = _.get(
              getOffset()(this.menuItemsToRefs[itemId]),
              "top",
            );

            const compensatedOffsetToMenuItem =
              menuItemOffset -
              stickyHeight -
              padding -
              this.state.warningMessageHeight -
              HEADER_HEIGHT;
            const windowScrollY = window.scrollY;
            const distanceToScroll = Math.abs(
              windowScrollY - compensatedOffsetToMenuItem,
            );
            if (distanceToScroll > 450) {
              console.log("skipahead");
              const directionSkipAhead =
                windowScrollY - compensatedOffsetToMenuItem < 0 ? -1 : 1;
              const skipAheadAmount = _.clamp(
                directionSkipAhead *
                Math.abs(windowScrollY - compensatedOffsetToMenuItem),
                -120,
                120,
              );
              window.scrollTo(0, compensatedOffsetToMenuItem + skipAheadAmount);
            }

            menuItemOffset
              ? _.defer(() => {
                try {
                  scroll.top(
                    page,
                    compensatedOffsetToMenuItem,
                    scrollToItemOptions,
                    setScrollingCompleted,
                  );
                } catch {
                  console.log("failed to scroll to item");
                }
              })
              : _.defer(() => {
                try {
                  scroll.top(
                    page,
                    2,
                    scrollToItemOptions,
                    setScrollingCompleted,
                  );
                } catch {
                  console.log("failed to scroll to item");
                }
              });
          } catch {
            console.log("failed to scroll to item");
          }
        },
      );
    } else {
      _.defer(() => {
        try {
          page &&
            scroll.top(page, 2, scrollToItemOptions, setScrollingCompleted);
        } catch {
          console.log("failed to scroll to item");
        }
      });
    }
  };

  registerMenuRef = (id, ref) => _.assign(this.topMenuIdsToRefs, { [id]: ref });

  registerMenuItemRef = (id, ref) =>
    ref && _.assign(this.menuItemsToRefs, { [id]: ref });

  getPrice = (item, branch, servingOptionId) => {
    if (!servingOptionId) {
      return null;
    }

    if (_.isEmpty(item.servingOptions)) {
      return null;
    }

    const servingOption = _.find(item.servingOptions, { servingOptionId });

    if (
      !servingOption ||
      _.isEmpty(servingOption.sizeOptions) ||
      _.isEmpty(servingOption.sizeOptions[0])
    ) {
      return null;
    }

    const innerPosMenuId = _.isEmpty(branch.innerPosMenuId)
      ? null
      : branch.innerPosMenuId;
    const prices = _.map(servingOption.sizeOptions, (sizeOption) => {
      if (_.isEmpty(sizeOption.posInnerMenuSpecificPrices)) {
        return sizeOption.price || null;
      }
      const posInnerMenuSpecificPrice = _.find(
        sizeOption.posInnerMenuSpecificPrices,
        { posInnerMenuId: innerPosMenuId },
      );
      return (
        (posInnerMenuSpecificPrice && _.isNumber(posInnerMenuSpecificPrice.price)) ? (posInnerMenuSpecificPrice.price || null) :
        (sizeOption.price  || null)
      );
    });
    return prices.length > 1 ? _.uniq([_.min(prices), _.max(prices)]) : prices;
  };

  registerSectionRef = (id, ref) =>
    _.assign(this.sectionRefsIdsToRefs, { [id]: ref });

  scrollToCategoryLabel = (categoryId) => {
    console.log("scrolling to category label for categoryId:", categoryId);
    if (this.preventScrollToCategoryLabel || !categoryId) {
      return;
    }
    try {
      const { rtl } = this.props.appStyles || {};
      this.menuWrapper =
        this.menuWrapper ||
        _.get(this.topMenuIdsToRefs[categoryId], "parentElement.parentElement");

      if (this.topMenuIdsToRefs[categoryId]) {
        const elementToScrollTo = this.topMenuIdsToRefs[categoryId]
          .parentElement;
        if (!this.props.app.isMobile) {
          scroll.top(
            this.menuWrapper,
            window.scrollY > 30 ? elementToScrollTo.offsetTop - 17 : 0,
            scrollToCategoryLabelOptions,
          );
        } else {
          if (!rtl) {
            scroll.left(
              this.menuWrapper,
              window.scrollY > 30 ? elementToScrollTo.offsetLeft - 17 : 0,
              scrollToCategoryLabelOptions,
            );
          } else {
            if (this.isSafari) {
              scroll.left(
                this.menuWrapper,
                window.scrollY > 30
                  ? elementToScrollTo.offsetLeft -
                  this.menuWrapper.clientWidth +
                  elementToScrollTo.offsetWidth +
                  34
                  : 0,
                scrollToCategoryLabelOptions,
              );
            } else {
              scroll.left(
                this.menuWrapper,
                window.scrollY > 30
                  ? elementToScrollTo.offsetLeft -
                  this.menuWrapper.clientWidth * 2 +
                  this.menuWrapper.scrollWidth +
                  elementToScrollTo.offsetWidth +
                  17
                  : this.menuWrapper.scrollWidth,
                scrollToCategoryLabelOptions,
              );
            }
          }
        }
      } else {
        console.warn("navigation menu out of sync");
      }
    } catch {
      console.log("failed to scroll to category label");
    }
  };
  onScrollUpdate = (ref) => {
    if (this.preventScrollToCategoryLabel || !ref) return;
    this.setState({ currentCategory: ref.id });

    this.scrollToCategoryLabel(ref.id);
  };

  findCategoryByName = (categoryName) => {
    const { menuData } = this.props;

    const cleanTargetCategoryName = categoryName
      .toLowerCase()
      .replace(/\s/g, "");

    const category = _.find(menuData, (category) => {
      const cleanCategoryName = category.name
        .toLowerCase()
        .replace(/&/g, "")
        .replace(/\s/g, "");

      return cleanCategoryName === cleanTargetCategoryName;
    });

    return _.get(category, "id");
  };

  scrollToCategoryName = (categoryName, ...params) => () => {
    const categoryId = this.findCategoryByName(categoryName);

    if (categoryId) {
      this.scrollToCategory(categoryId, ...params)();
    }
  };

  scrollToCategory = (categoryId, stickyHeight, options = {}) => () => {
    const { isFirst = false } = options;

    if (this.props.app.isSSR) {
      return;
    }

    console.log("scroll to category:", categoryId);

    this.preventScrollToCategoryLabel = true;

    const elementToScrollTo = _.get(
      this.topMenuIdsToRefs[categoryId],
      "parentElement",
    );
    this.menuWrapper =
      this.menuWrapper || _.get(elementToScrollTo, "parentElement");

    if (!elementToScrollTo || !this.menuWrapper) {
      console.warn("elementToScrollTo or menu wrapper not found");
      return;
    }
    const { rtl } = this.props.appStyles || {};

    const enableScroll = () => {
      this.preventScrollToCategoryLabel = false;
    };

    this.setState({ scrollingToCategory: true }, () => {
      const offsetToCategory = getOffset()(
        this.sectionRefsIdsToRefs[categoryId],
      ).top;
      const compensatedOffsetToCategory = isFirst
        ? 0
        : offsetToCategory -
        stickyHeight -
        30 -
        this.state.warningMessageHeight -
        HEADER_HEIGHT;
      const windowScrollY = window.scrollY;
      const distanceToScroll = Math.abs(
        windowScrollY - compensatedOffsetToCategory,
      );
      if (distanceToScroll > 450) {
        const directionSkipAhead =
          windowScrollY - compensatedOffsetToCategory < 0 ? -1 : 1;
        const clampLimit = this.props.app.isMobile ? 450 : 1000;
        const skipAheadAmount = _.clamp(
          directionSkipAhead *
          Math.abs(windowScrollY - compensatedOffsetToCategory),
          -clampLimit,
          clampLimit,
        );
        try {
          window.scrollTo(0, compensatedOffsetToCategory + skipAheadAmount);
        } catch (e) {
          console.log("failed to scroll");
        }
      }
      try {
        scroll.top(
          page,
          compensatedOffsetToCategory,
          {
            ease: ease.inOutEase,
            duration: scrollToCategorySectionDuration,
          },
          () =>
            this.setState({ scrollingToCategory: false }, () => {
              clearTimeout(this.scrollToCategoryTimeout);
              this.scrollToCategoryTimeout = setTimeout(() => {
                if (!this.props.app.isMobile) {
                  scroll.top(
                    this.menuWrapper,
                    window.scrollY > 30 ? elementToScrollTo.offsetTop - 17 : 0,
                    scrollToCategoryLabelOptions,
                    enableScroll,
                  );
                } else {
                  if (!rtl) {
                    scroll.left(
                      this.menuWrapper,
                      window.scrollY > 30
                        ? elementToScrollTo.offsetLeft - 17
                        : 0,
                      scrollToCategoryLabelOptions,
                      enableScroll,
                    );
                  } else {
                    if (this.isSafari) {
                      scroll.left(
                        this.menuWrapper,
                        window.scrollY > 30
                          ? elementToScrollTo.offsetLeft -
                          this.menuWrapper.clientWidth +
                          elementToScrollTo.offsetWidth +
                          34
                          : 0,
                        scrollToCategoryLabelOptions,
                        enableScroll,
                      );
                    } else {
                      scroll.left(
                        this.menuWrapper,
                        window.scrollY > 30
                          ? elementToScrollTo.offsetLeft -
                          this.menuWrapper.clientWidth * 2 +
                          this.menuWrapper.scrollWidth +
                          elementToScrollTo.offsetWidth +
                          17
                          : this.menuWrapper.scrollWidth,
                        scrollToCategoryLabelOptions,
                        enableScroll,
                      );
                    }
                  }
                }
              }, scrollToCategoryLabelDelay);
            }),
        );
      } catch (e) {
        console.error("failed to scroll:", e);
      }
    });
  };

  navigateToMenuItem = (item) => () => {
    const {
      location,
      pageContext: {
        business: { trackings, currency },
      },
    } = this.props;

    this.setState({ menuItemClicked: item.id });

    fireSelectItemEvent({ ...item, currency });

    const params = getParams(location);
    navigateTo(
      setParams(`/order/items/${item.id}`, {
        ...params,
        itemName: item.name,
        fromItemId: item.id,
      }),
    );
  };

  selectDeal = (deal) => {
    this.setState({ menuItemClicked: deal.id });

    this.props.selectDeal(deal);
  };

  getCurrentBranchOpeningTime = () => {
    const { branchesAvailability, currentBranch, appStyles = {} } = this.props;

    if (_.isEmpty(currentBranch) || _.isEmpty(branchesAvailability)) {
      return null;
    }

    const branchAvailability = branchesAvailability[currentBranch.id];
    if (!branchAvailability || !branchAvailability.availableFrom) {
      console.warn(
        `branch availabilty not found for branchId: ${currentBranch.id}`,
      );
      return null;
    }

    const {
      availableFrom,
      branch: { timeZoneStr },
    } = branchAvailability;

    return moment
      .tz(availableFrom, timeZoneStr)
      .format(localeDates[appStyles.locale || "en-US"].hourFormat);
  };

  getUnlockedDealIds = (promoCodes) =>
    _.uniq(_.flatMap(promoCodes, "unlockedDiscountRuleIds"));

  getDisplayedDeals = () => {
    const { order, user, deals } = this.props;
    const userGroupIds = _.get(user, "loyaltyProfile.data.groupIds", []);

    const unlockedDealIds = this.getUnlockedDealIds(
      _.get(order, "checkoutResponse.order.promoCodes"),
    );
    const availableDeals = _.map(
      _.filter(
        deals,
        (deal) =>
          deal &&
          !(
            deal.isLocked &&
            !deal.displayWhenLocked &&
            !_.includes(unlockedDealIds, deal.id)
          ) &&
          (_.isEmpty(deal.userGroupIds) ||
            _.intersection(deal.userGroupIds, userGroupIds).length),
      ),
      (deal) => ({
        ...deal,
        ...(_.includes(unlockedDealIds, deal.id) && { userUnlockedDeal: true }),
      }),
    );

    return _.sortBy(availableDeals, [
      "isLocked",
      "userUnlockedDeal",
      ["discountRuleType", "fixed"],
    ]);
  };

  onOpenModal = ({ modalType }) => () => {
    this.setState({ modalOpen: true, modalType });
  };
  onCloseModal = () => {
    const { location } = this.props;
    const shouldDismissPointBankNotice =
      this.state.modalType === POINT_COUPON_TRADE_NOTICE;

    navigateTo(removeParams(location, ["checkitem"]));

    this.setState({ modalOpen: false, modalType: null }, () => {
      if (shouldDismissPointBankNotice) {
        this.props.dismissTradePointsNotice();
      }
    });
  };

  handleCheckoutClicked = () => {
    const {
      location,
      openCheckoutView,
      user,
      pageContext: { businessAppConfiguration },
      openAuthView,
    } = this.props;
    const { requireLoginForOrder } = businessAppConfiguration;
    if (requireLoginForOrder && !user.loggedIn) {
      openAuthView();
    } else {
      openCheckoutView();
      navigateTo(
        setParams(`/order`, {
          ...getParams(location),
          checkoutOpen: true,
        }),
      );
    }
  };

  onSearchUpdate = ({ search, searchResults }) => {
    this.setState({ search, searchResults: _.map(searchResults, "ref") });
  };

  handleLocationSelected = ({branchId, servingOption, userAddress}) => {
    const selectedBranch = _.get(this.props.branchesAvailability, branchId);
    const selectedServingOption = servingOption || this.props.selectedServingOption;

    const defaultTip = get(
      selectedBranch,
      `servingOptionTypeToDefaultSelectedTipOption[${this.props.selectedServingOption.type}]`,
    );

    this.props.setUserSelectedLocationAndServingOption({
      servingOption: selectedServingOption,
      locationId: branchId,
      defaultTip:
        (defaultTip && selectedServingOption.offersTip) ||
        (selectedServingOption.needsAddress &&
          !_.get(selectedBranch, "branch.disableDeliveryTip"))
          ? defaultTip
          : 0,
      firstAvailableDate: _.get(selectedBranch, "availableFrom"),
      userAddress: userAddress,
    });

    this.setState({ modalOpen: false });
  };

  render() {
    const {
      appStyles,
      pageContext: {
        branches,
        servingOptions,
        business,
        businessAppConfiguration,
      },
      T,
      location,
      isSavingOrder,
      app: { isSSR, keyboardOpen, isMobile },
      menuData,
      user,
      order,
      pickupTimes,
      priceDetails,
      deals,
      selectedServingOption,
      branchesAvailability,
      currentBranch,
      predictedOrderPrice,
      setTableCode,
      addPromoCode,
      removePromoCode,
      openAuthView,
      locations,
      userFavorites,
    } = this.props;

    const { search, searchResults } = this.state;
    const showSearchResults = !_.isEmpty(search);

    const { promoCodesEnabled, hasPointBank } = businessAppConfiguration;
    const { currencySymbol, allergens } = business;
    const {
      backgroundColor,
      Order: { MenuTitle } = {},
      TitleOnBackground = {},
      CategoryName = {}
    } = appStyles;
    const showCategoriesImages = true;
    const params = getParams(location);
    const menuNotices = _.get(currentBranch, "menuNotices");

    const toCategoryId = sanitizeParam(params.toCategoryId);
    const showSelectedCategory = isMobile && toCategoryId;

    const filterBySearch = (searchResults, menuData) =>
      showSearchResults
        ? _.filter(
            _.map(menuData, (category) => ({
              ...category,
              items: _.filter(category.items, (item) =>
                _.includes(searchResults, item.id)
              ),
            })),
            "items.length"
          )
        : menuData;

    const filterByCategoryId = (menuData) =>
      showSelectedCategory
        ? _.filter(menuData, (category) => category.id === toCategoryId)
        : menuData;

    const applyFilters = _.flow([filterBySearch, filterByCategoryId]);

    const filteredMenuData = applyFilters(searchResults, menuData);

    const currentBranchOpeningTime = this.getCurrentBranchOpeningTime();

    const categoryIds = _.map(filteredMenuData, (category) => category.id);

    const branch = currentBranch || branches[0];
    const servingOptionId = _.get(
      selectedServingOption || servingOptions[0],
      "id",
    );

    const shouldShowWarningMessage =
      this.state.layoutReady &&
      !isSSR &&
      !pickupTimes.loading &&
      pickupTimes.status !== "AVAILABLE_NOW";

    const checkoutContainer =
      !isSSR && document && document.getElementById("checkout");

    const isBranchNotAvailable =
      pickupTimes.error &&
      pickupTimes.error === "BRANCH_AVAILABILITY_NOT_FOUND";

    const nonCurrentCategoryLabelClassName = classnames(
      styles.CategoryLabel,
      "noselect",
      appStyles.shouldScaleCategoryMenu
        ? styles.CurrentSectionWithScale
        : styles.CurrentSectionWithColor,
    );

    const scrolling =
      this.state.scrollingToItem ||
      this.state.scrollingToCategory ||
      this.state.shouldScrollToItem;

    const checkoutOpen = this.isCheckoutOpen();
    const moreThanOneBranchAvailable = _.size(branchesAvailability) > 1;

    const effectiveDiscountDealsForOrder = _.map(
      _.get(order, "checkoutResponse.order.discountItems"),
      "discountRuleId",
    );

    const displayedDeals = this.getDisplayedDeals();
    const hasDeals =
      !showSearchResults &&
      !showSelectedCategory &&
      !_.isEmpty(displayedDeals);
    const hasFavorites =
      !showSearchResults &&
      !showSelectedCategory &&
      !_.isEmpty(userFavorites) &&
      !appStyles.hideFavoriteItemsCategory;

    const availableServingOptionsForBranch = currentBranch ? _.filter(this.props.availableServingOptions, (servingOption) => 
      !_.includes(servingOption.unavailableInBranches, currentBranch.id)) : this.props.availableServingOptions;

    const getModalContent = () => {
      switch (this.state.modalType) {
        case SERVING_OPTION_VALUE:
          return(
            <ServingOption 
              availableServingOptions={availableServingOptionsForBranch}
              selectedServingOption={this.props.selectedServingOption}
              appStyles={appStyles}
              order={order}
              branch={branch}
              T={T}
              onClose={() => {this.setState({modalOpen: false})}/*this.onCloseModal*/}
              onSetServingOption={(servingOption, userAddress) => {
                console.log("onSetServingOption", servingOption);
                this.setState({ modalOpen: false }, () => {
                  if (userAddress){
                    this.handleLocationSelected({branchId: branch.id, servingOption, userAddress});
                  }else{
                    this.props.setOrderServingOption(servingOption)                    
                  }
                  navigateTo(
                    setParams("/order", {
                      ...params,
                      servingOptionType: servingOption.type,
                    }),
                  );
                });
              }}
            />)
        case ADDRESS:
          return (
            <OrderAddress 
              user={user}
              order={order}
              currentBranch={branch}
              businessAppConfiguration={businessAppConfiguration}
              appStyles={appStyles}
              T={T}
              onSetAddressClick={(userAddress) => {
                this.handleLocationSelected({branchId: branch.id, servingOption: selectedServingOption, userAddress});
                this.setState({ modalOpen: false });
              }}
            />
          )
        case BRANCH:
          return (
            <OrderBranch 
              branches={branches}
              selectedBranch={branch}
              appStyles={appStyles}
              locations={locations}
              servingOption={this.props.selectedServingOption}
              branchesAvailability={branchesAvailability}
              onSetBranch={this.handleLocationSelected}
              selectedServingOption={this.props.selectedServingOption}
              T={T}
              params={params}
              pageContext={this.props.pageContext}
              deliveryOptions={this.props.deliveryOptions}
              order={order}
              user={user}
            />
          )
        case TABLE_CODE:
          return (
            <TableCode
              T={T}
              appStyles={appStyles}
              onClose={() => {}/*this.onCloseModal*/}
              branchAvailability={branchesAvailability[currentBranch.id]}
              tableCode={order.tableCode}
              setTableCode={setTableCode}
              preSetTableCode={(tableCode) =>
                navigateTo(
                  setParams("/order", {
                    ...params,
                    tableCode,
                  }),
                )
              }
              onSetTableCode={(tableCode) => {
                this.setState({ modalOpen: false }, () => {
                  console.log(
                    setParams("/order", {
                      branchId: branch.id,
                      branchName: branch.name,
                      tableCode,
                    }),
                  );
                  navigateTo(
                    setParams("/order", {
                      branchId: branch.id,
                      branchName: branch.name,
                      tableCode,
                    }),
                  );
                });
              }}
            />
          );

        case PROMO_CODE:
          return (
            <PromoCode
              T={T}
              appStyles={appStyles}
              addPromoCode={addPromoCode}
              removePromoCode={removePromoCode}
              promoCodes={order.promoCodes}
              servingOptionTag={selectedServingOption.servingOptionTag}
              branch={currentBranch.name}
              loading={isSavingOrder}
              tight
            />
          );
        case POINT_COUPON_TRADE_NOTICE:
          return (
            !_.get(
              businessAppConfiguration,
              "enableUnlockRewardsFlow"
            ) ? (
              <PointsCouponNotice
                appStyles={appStyles}
                T={T}
                onClose={this.onCloseModal}
                user={user}
              />
            ) : null
          );
        case CHECK_DEAL_NOTICE:
          const dealToCheck = _.find(displayedDeals, {
            id: params.checkdeal,
          });
          return (
            <CheckDealNotice
              appStyles={appStyles}
              T={T}
              onClose={this.onCloseModal}
              deal={dealToCheck}
              onDealClicked={
                !dealToCheck || isDisplayOnlyDeal(dealToCheck)
                  ? _.noop
                  : dealToCheck.isLocked && !dealToCheck.userUnlockedDeal
                    ? this.onOpenModal({ modalType: PROMO_CODE })
                    : () => this.selectDeal(dealToCheck)
              }
            />
          );
        case CHECK_ITEM_NOTICE:
          const itemToCheck = _.find(_.flatMap(menuData, "items"), {
            id: params.checkitem,
          });
          const itemId = _.get(itemToCheck, "id");
          const itemName = _.get(itemToCheck, "name");

          const itemAllergens = _.get(itemToCheck, "allergens");
          return (
            <CheckItemNotice
              appStyles={appStyles}
              T={T}
              onClose={() => {
                navigateTo(removeParams(location, ["checkitem"]));
                this.onCloseModal();
              }}
              item={itemToCheck}
              onItemClicked={() => {
                navigateTo(
                  removeParams(
                    setParams(`/order/items/${itemId}${location.search}`, {
                      ...params,
                      itemName,
                      fromItemId: itemId,
                    }),
                    ["checkitem"],
                  ),
                );
              }}
              allergens={_.filter(allergens, (allergen) =>
                _.includes(itemAllergens, allergen.id),
              )}
              prices={
                itemToCheck &&
                this.getPrice(itemToCheck, branch, servingOptionId)
              }
              currencySymbol={currencySymbol}
              businessAppConfiguration={businessAppConfiguration}
            />
          );
        default:
          return null;
      }
    };

    const getModal = (modalType) => {
      const modalContent = getModalContent();

      return (
        !_.isNil(modalContent) && (
          <Modal
            appStyles={appStyles}
            open={this.state.modalOpen}
            onClose={this.onCloseModal}
            style={
              modalType === TABLE_CODE && {
                width: "80%",
              }
            }
            center
            focusTrapped
          >
            {modalContent}
          </Modal>
        )
      );
    };

    const getScrollSpyItems = () => {
      //the ScrollSpy component counts all nested entities even if it's not rendered, so need to add empty string at the beginning
      const items = ["", "",...categoryIds];
      
      if (hasFavorites) {
        items[0] = "favorites";
      }

      if (hasDeals) {
        items[1] = "deals";
      }
      
      return items;
    }

    return (
      <AppContainer.Content
        tightBottom
        style={{ animation: "none" }}
        appStyles={appStyles}
      >
        {this.state.layoutReady && getModal(this.state.modalType)}
        <StickyContainer>
          {shouldShowWarningMessage && (
            <WarningMessage
              T={T}
              appStyles={appStyles}
              checkoutOpen={checkoutOpen}
              branchName={branch.name}
              isBranchNotAvailable={isBranchNotAvailable}
              currentBranchOpeningTime={currentBranchOpeningTime}
              pickupTimes={pickupTimes}
              onRetryClicked={
                isBranchNotAvailable
                  ? () => navigateTo("/find-location")
                  : this.props.loadBranchAvailabiltyIfNeeded
              }
              hasDelayedOrder={selectedServingOption.offersPickupTime}
              onResize={(el) =>
                el.height !== this.state.warningMessageHeight &&
                !isSSR &&
                this.setState({ warningMessageHeight: el.height })
              }
              locationClosedMessage={
                currentBranch
                  ? getLocationClosedText({
                    T,
                    locale: appStyles.locale,
                    servingOptionTag: selectedServingOption.servingOptionTag,
                    availableFrom: _.get(
                      branchesAvailability,
                      `[${currentBranch.id}].availableFrom`,
                    ),
                    closedMessage: _.get(
                      branchesAvailability,
                      `[${currentBranch.id}].closedMessage`,
                    ),
                    timeZoneStr: currentBranch.timeZoneStr,
                    servingOptionType: selectedServingOption.type,
                  })
                  : ""
              }
              selectedServingOptionType={selectedServingOption.type}
            />
          )}
          {shouldShowWarningMessage && (
            <div style={{ height: this.state.warningMessageHeight || 0 }} />
          )}

          <MenuHeader
            isSSR={isSSR}
            T={T}
            appStyles={appStyles}
            branch={branch}
            user={user}
            classNames={classnames(
              styles.ResponsiveHeader,
              checkoutOpen && styles.ResponsiveHeaderCheckoutOpen,
              appStyles.rtl && styles.RTL,
            )}
            onResize={(el) =>
              el.height !== this.state.menuHeaderHeight &&
              this.setState({ menuHeaderHeight: el.height })
            }
            selectedServingOption={selectedServingOption}
            moreThanOneBranchAvailable={moreThanOneBranchAvailable}
            allergens={allergens}
            checkoutOpen={checkoutOpen}
            tableCode={order.tableCode}
            openTableCodeModal={() => {
              resetTableCode();
              navigateTo(removeParams(location, "tableCode"));
            }}
            displayLoyaltyProfile={hasPointBank}
          />
          <Sticky topOffset={this.state.menuHeaderHeight - 60}>
            {({ style, calculatedHeight: stickyHeight = 0 }) => (
                <div
                  style={{
                    ...style,
                    top: shouldShowWarningMessage
                      ? this.state.warningMessageHeight + 60
                      : 60,
                      ...(showSelectedCategory && { display: "none" })
                  }}
                  className={classnames(
                    styles.ResponsiveHeader,
                    checkoutOpen && styles.ResponsiveHeaderCheckoutOpen,
                    appStyles.rtl && styles.RTL,
                  )}
                >
                  <nav style={{ backgroundColor }}>
                    {!isSSR && (
                      <Scrollspy
                        style={{
                          ...appStyles.CategoryMenu,
                          // temporary hack before refactoring this menu
                          ...(_.get(
                            appStyles,
                            "CategoryMenu.backgroundColor",
                          ) && {
                            background: appStyles.CategoryMenu.backgroundColor,
                          }),
                          maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${this.state.warningMessageHeight
                            }px - ${STICKY_MENU_PADDING})`,
                        }}
                        items={getScrollSpyItems()}
                        currentClassName={
                          scrolling ? undefined : styles.CurrentSection
                        }
                        offset={
                          -stickyHeight -
                          20 -
                          this.state.warningMessageHeight -
                          HEADER_HEIGHT
                        }
                        onUpdate={_.throttle(this.onScrollUpdate, 300)}
                        className={classnames(
                          styles.CategoryMenu,
                          appStyles.rtl && styles.RTL,
                          appStyles.menuItemImageFocusStyle &&
                          styles.NoShadowDesktopMenu,
                          appStyles.darkCategoryMenuBackground &&
                          styles.LightLabel,
                          appStyles.displayCategoriesImages &&
                          styles.DisplayCategoriesImages,
                        )}
                      >
                        {
                          hasFavorites && (
                            <li
                            className={nonCurrentCategoryLabelClassName}
                            onClick={_.debounce(
                              this.scrollToCategory("favorites", stickyHeight, {
                                isFirst: hasFavorites,
                              }),
                              150,
                            )}
                            key={"favorites"}
                            style={{
                              color: appStyles.categoryMenuSelectedColor,
                              ...(appStyles.rtl
                                ? { paddingLeft: 27 }
                                : { paddingRight: 27 }),
                              ...appStyles.CategoryMenuLabel,
                            }}
                          >
                            <CategoryLabel
                              category={{
                                name: T("Favorites"),
                                items: userFavorites,
                              }}
                              refEl={(el) => this.registerMenuRef("favorites", el)}
                              isCurrent={this.state.currentCategory === "favorites"}
                              appStyles={appStyles}
                              showCount={showSearchResults}
                              T={T}
                            />
                          </li>
                          )
                        }
                        {hasDeals && (
                          <li
                            className={nonCurrentCategoryLabelClassName}
                            onClick={_.debounce(
                              this.scrollToCategory("deals", stickyHeight, {
                                isFirst: !hasFavorites,
                              }),
                              150,
                            )}
                            key={"deals"}
                            style={{
                              color: appStyles.categoryMenuSelectedColor,
                              ...(appStyles.rtl
                                ? { paddingLeft: 27 }
                                : { paddingRight: 27 }),
                              ...appStyles.CategoryMenuLabel,
                            }}
                          >
                            <CategoryLabel
                              category={{
                                name: T("Deals"),
                                items: displayedDeals,
                              }}
                              refEl={(el) => this.registerMenuRef("deals", el)}
                              isCurrent={this.state.currentCategory === "deals"}
                              appStyles={appStyles}
                              showCount={showSearchResults}
                              T={T}
                            />
                          </li>
                        )}
                        {_.map(filteredMenuData, (category, index) => (
                          <li
                            className={nonCurrentCategoryLabelClassName}
                            onClick={_.debounce(
                              this.scrollToCategory(category.id, stickyHeight, {
                                isFirst: !hasDeals && index === 0,
                              }),
                              150,
                            )}
                            key={category.id}
                            style={{
                              color: appStyles.categoryMenuSelectedColor,
                              ...(appStyles.rtl
                                ? { paddingLeft: 27 }
                                : { paddingRight: 27 }),
                            }}
                          >
                            <CategoryLabel
                              showCount={showSearchResults}
                              category={category}
                              refEl={(el) =>
                                this.registerMenuRef(category.id, el)
                              }
                              isCurrent={
                                this.state.currentCategory === category.id
                              }
                              appStyles={appStyles}
                              T={T}
                            />
                          </li>
                        ))}
                        <Collapse
                          in={_.isEmpty(searchResults) && showSearchResults}
                        >
                          <Fade
                            in={_.isEmpty(searchResults) && showSearchResults}
                          >
                            <li
                              className={nonCurrentCategoryLabelClassName}
                              onClick={_.noop}
                              key={"search"}
                              style={{
                                color: appStyles.categoryMenuSelectedColor,
                                ...(appStyles.rtl
                                  ? { paddingLeft: 27 }
                                  : { paddingRight: 27 }),
                              }}
                            >
                              <CategoryLabel
                                category={{ name: T("Search Results") }}
                                refEl={(el) =>
                                  this.registerMenuRef("search", el)
                                }
                                isCurrent={
                                  this.state.currentCategory === "search"
                                }
                                appStyles={appStyles}
                                T={T}
                              />
                            </li>
                          </Fade>
                        </Collapse>
                      </Scrollspy>
                    )}
                  </nav>
                </div>
              )}
          </Sticky>
          <div style={{ display: "flex" }}>
            <div
              className={classnames(styles.ResponsiveCategoryMenuCompensation)}
            />
            {checkoutContainer && (
              <Portal node={checkoutContainer}>
                <div />
                {/*bug in portal which transfers classes to the top div?*/}
                <CheckoutView
                  {...this.props}
                  sidebarMode
                  hideWarningMessage={shouldShowWarningMessage}
                  currentBranchOpeningTime={currentBranchOpeningTime}
                />
              </Portal>
            )}

            <div
              className={classnames(
                styles.MenuContainer,
                checkoutOpen && styles.CheckoutOpen,
                appStyles.rtl && styles.RTL,
                scrolling && styles.Scrolling,
              )}
            >
              {!appStyles.disableMenuSearch && (
                <MenuSearch
                  menuData={this.props.pageContext.menuData}
                  onChange={this.onSearchUpdate}
                />
              )}
              <MenuNotices
                menuNotices={_.filter(
                  menuNotices,
                  (menuNotice) => _.get(menuNotice, "displayType") !== "bottom",
                )}
                appStyles={appStyles}
                T={T}
              />
              {!isSSR && hasFavorites &&
                  (<section id={"favorites"} key={"favorites"}>
                    <div
                      className={classnames(
                        styles.CategoryTitle,
                        styles.Margin,
                        appStyles.rtl && styles.RTL,
                        promoCodesEnabled &&
                        !hasDeals &&
                        styles.DealCategoryTitleMobile,
                      )}
                    >
                      <div 
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "10px 16px"
                        }}>
                        {!appStyles.noIcons && <Favorite className={styles.GiftIcon}/>}
                        <h2
                          ref={(el) => this.registerSectionRef("favorites", el)}
                          style={{ 
                            ...MenuTitle,
                            ...TitleOnBackground,
                            margin: 0,
                            padding: "0 10px"
                          }}
                        >
                          {T("Favorites")}
                        </h2>
                      </div>
                    </div>
                    <List classNames={styles.CategoryItems}>
                      {(!isMobile || (isMobile && !checkoutOpen)) &&
                        _.map(userFavorites, (item, index) => {
                          const prices = this.getPrice(
                            item,
                            branch,
                            servingOptionId,
                          );
                          return (
                            <MenuItem
                              menuItemId={item.id}
                              T={T}
                              appStyles={appStyles}
                              isMenuItem
                              useCategoryImage={item.useCategoryImage}
                              title={item.name}
                              description={item.description}
                              key={item.id}
                              imagePreview={item.imagePreview}
                              imageKey={item.imageKey}
                              additionalImages={item.additionalImages}
                              onClick={this.navigateToMenuItem({
                                ...item,
                                index,
                                displayedPrice: _.get(prices, 0),
                                categoryName: "Favorites",
                              })}
                              refEl={(el) =>
                                this.registerMenuItemRef(item.id, el)
                              }
                              classNames={styles.CategoryItem}
                              loading={this.state.menuItemClicked === item.id}
                              imageObjectFit={{
                                ...(item.imageObjectFit && {
                                  objectFit:
                                    item.imageObjectFit === "fit"
                                      ? "contain"
                                      : item.imageObjectFit,
                                }),
                              }}
                              allergens={_.filter(allergens, (allergen) =>
                                _.includes(item.allergens, allergen.id),
                              )}
                              prices={prices}
                              currencySymbol={currencySymbol}
                              businessAppConfiguration={businessAppConfiguration}
                              userLoggedIn={user.loggedIn}
                              calories={item.calories}
                            />
                          );
                        })}
                    </List>
                  </section>)
                }
              {!isSSR && hasDeals && (
                <section id={"deals"} key={"deals"}>
                  <div
                    className={classnames(
                      styles.CategoryTitle,
                      styles.Margin,
                      appStyles.rtl && styles.RTL,
                      promoCodesEnabled && styles.DealCategoryTitleMobile,
                    )}
                  >
                    <div 
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 16px"
                      }}>
                      {!appStyles.noIcons && <DealsIcon className={styles.GiftIcon} style={{marginBottom: "5px"}}/>}
                      <h2
                        ref={(el) => this.registerSectionRef("deals", el)}
                        style={{ 
                          ...MenuTitle,
                          ...TitleOnBackground,
                          margin: 0,
                          padding: "0 10px"
                        }}
                      >
                        {T("Deals")}
                      </h2>
                    </div>
                    {promoCodesEnabled && (
                      <AddPromoCodeButton
                        T={T}
                        appStyles={appStyles}
                        onClick={this.onOpenModal({
                          modalType: PROMO_CODE,
                        })}
                      />
                    )}
                  </div>
                  <List classNames={styles.CategoryItems}>
                    {_.map(displayedDeals, (deal) => (
                      <MenuItem
                        isDeal
                        T={T}
                        appStyles={appStyles}
                        key={deal.id}
                        title={deal.header}
                        imageKey={deal.imageKey}
                        imagePreview={deal.imagePreview}
                        description={deal.description}
                        onClick={() => {
                          isDisplayOnlyDeal(deal) &&
                            !(deal.mustBeLoggedIn && !this.props.user.loggedIn)
                            ? 
                              deal.isLocked && !deal.userUnlockedDeal ?
                                   this.onOpenModal({ modalType: PROMO_CODE })() :
                                   _.noop
                            : deal.isLocked && !deal.userUnlockedDeal ? 
                                   this.onOpenModal({ modalType: PROMO_CODE })() : 
                                   deal.mustBeLoggedIn && !this.props.user.loggedIn
                                      ? openAuthView(LOGIN_TYPES.DEALS)
                                      : this.selectDeal(deal);
                        }}
                        refEl={(el) => this.registerMenuItemRef(deal.id, el)}
                        itemToAnimate={
                          _.isEqual(
                            deal.id,
                            this.state.unlockedDealIdToScrollTo,
                          ) &&
                          this.state.animateDealElement &&
                          this.menuItemsToRefs[deal.id]
                        }
                        totalDealPrice={(deal.discountRuleType === "fixed" && deal.fixedDiscountType === "fixedDiscount") ? -1 * deal.totalDealPrice: deal.totalDealPrice}
                        isLocked={
                          deal.isLocked ||
                          (deal.mustBeLoggedIn && !this.props.user.loggedIn)
                        }
                        isDealUsedInOrder={_.includes(
                          effectiveDiscountDealsForOrder,
                          deal.id,
                        )}
                        userUnlockedDeal={deal.userUnlockedDeal}
                        shouldAnimateCard={
                          _.isEqual(
                            deal.id,
                            this.state.unlockedDealIdToScrollTo,
                          ) && this.state.animateDealElement
                        }
                        lockDescription={
                          deal.lockMessage
                            ? deal.lockMessage
                            : deal.mustBeLoggedIn && !this.props.user.loggedIn
                              ? T("Login or sign up to unlock this deal")
                              : undefined
                        }
                        classNames={styles.CategoryItem}
                        loading={this.state.menuItemClicked === deal.id}
                      />
                    ))}
                  </List>
                </section>
              )}
              {!isSSR &&
                _.map(filteredMenuData, (category, index) => (
                  <section id={category.id} key={category.id}>
                    <div
                      className={classnames(
                        styles.CategoryTitle,
                        styles.Margin,
                        appStyles.rtl && styles.RTL,
                        promoCodesEnabled &&
                        !hasDeals &&
                        styles.DealCategoryTitleMobile,
                      )}
                      style={{ margin: "16px 16px 0 16px" }}
                    >
                      <div
                        ref={(el) => this.registerSectionRef(category.id, el)}
                        className={styles.CategoryName}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          ...TitleOnBackground,
                          ...MenuTitle,
                          ...CategoryName
                        }}
                      >
                        {T(category.name)}{" "}
                        {showSearchResults && (
                          <Chip label={category.items.length} />
                        )}
                      </div>
                      {promoCodesEnabled && !hasDeals && index === 0 && (
                        <AddPromoCodeButton
                          T={T}
                          appStyles={appStyles}
                          onClick={this.onOpenModal({
                            modalType: PROMO_CODE,
                          })}
                          style={{ flexShrink: 0, margin: 0 }}
                        />
                      )}
                    </div>
                    <div className={styles.CategoryDescription}>
                      {category.description && T(category.description)}
                    </div>
                    <List classNames={styles.CategoryItems}>
                      {(!isMobile || (isMobile && !checkoutOpen)) &&
                        _.map(category.items, (item, index) => {
                          const prices = this.getPrice(
                            item,
                            branch,
                            servingOptionId,
                          );
                          return (
                            <MenuItem
                              menuItemId={item.id}
                              T={T}
                              appStyles={appStyles}
                              isMenuItem
                              useCategoryImage={item.useCategoryImage}
                              title={item.name}
                              description={item.description}
                              key={item.id}
                              imagePreview={item.imagePreview}
                              imageKey={item.imageKey}
                              additionalImages={item.additionalImages}
                              onClick={this.navigateToMenuItem({
                                ...item,
                                index,
                                displayedPrice: _.get(prices, 0),
                                categoryName: category.name,
                              })}
                              refEl={(el) =>
                                this.registerMenuItemRef(item.id, el)
                              }
                              classNames={styles.CategoryItem}
                              loading={this.state.menuItemClicked === item.id}
                              imageObjectFit={{
                                ...(item.imageObjectFit && {
                                  objectFit:
                                    item.imageObjectFit === "fit"
                                      ? "contain"
                                      : item.imageObjectFit,
                                }),
                              }}
                              allergens={_.filter(allergens, (allergen) =>
                                _.includes(item.allergens, allergen.id),
                              )}
                              prices={prices}
                              currencySymbol={currencySymbol}
                              businessAppConfiguration={businessAppConfiguration}
                              userLoggedIn={user.loggedIn}
                              calories={item.calories}
                            />
                          );
                        })}
                    </List>
                  </section>
                ))}

              <MenuNotices
                menuNotices={_.filter(
                  menuNotices,
                  (menuNotice) => _.get(menuNotice, "displayType") === "bottom",
                )}
                appStyles={appStyles}
                T={T}
              />
            </div>
          </div>

          {!checkoutOpen && order.orderItems.length > 0 && (
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              ref={(el) => {
                this.footer = el;
              }}
              hideOnDesktop
              appStyles={appStyles}
              transparentGradient
              center
            >
              <AppContainer.Footer.Button
                appStyles={appStyles}
                spread
                onClick={this.handleCheckoutClicked}
              >
                <span>{T("View Order")}</span>
                <span style={{ display: "flex", alignItems: "center" }}>
                  {!isSavingOrder &&
                    priceDetails &&
                    predictedOrderPrice > 0 && (
                      <span
                        style={
                          appStyles.rtl
                            ? { marginLeft: 10 }
                            : { marginRight: 8 }
                        }
                      >
                        {_.isNumber(predictedOrderPrice)
                          ? `${currencySymbol}${predictedOrderPrice.toFixed(
                            appStyles.pricesFixedPoint,
                          )}`
                          : ""}
                      </span>
                    )}
                  <BagItemsIndicator
                    ref={(el) => {
                      this.bounceIndicator = el;
                    }}
                    counter={order.orderItems.length}
                    appStyles={appStyles}
                  />
                </span>
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          )}
        </StickyContainer>
      </AppContainer.Content>
    );
  }
}

const HEADER_HEIGHT = 60;
const STICKY_MENU_PADDING = "44px";
const STICKY_CATEGORY_MENU_HEIGHT = 60;
const STICKY_CATEGORY_MENU_WITH_IMAGES_HEIGHT = 100;
const POINT_COUPON_TRADE_NOTICE = "pctn";
const CHECK_DEAL_NOTICE = "cdn";
const CHECK_ITEM_NOTICE = "cin";

export default OrderView;
// export default debounceRender(OrderView, 100, { lead: true });
